import { FormItem, Input } from 'design-system/input';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateITHTimerMutation } from 'store/devices/devicesApi';
import { selectDeviceSelected } from 'store/devices/devicesSlice';

export const ITHConfigClockRango = ({
  sectorId,
  labelOn,
  labelOff,
}: {
  sectorId: number;
  labelOn: string;
  labelOff: string;
}) => {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '', fanTimers = [] } = deviceSelected || {};
  const [updateITHTimer] = useUpdateITHTimerMutation();

  const { start, stop } = fanTimers.find((timer) => timer.id === '7' && timer.sector === String(sectorId)) || {};

  const [valueOn, setValueOn] = useState<number>(0);
  const [valueOff, setValueOff] = useState<number>(0);

  useEffect(() => {
    if (start) setValueOn(Number(start));
    if (stop) setValueOff(Number(stop));
  }, [start, stop]);

  return (
    <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
      <div className="min-w-[80px]">
        <FormItem label={labelOn}>
          <Input
            key={valueOn}
            placeholder={labelOn}
            defaultValue={valueOn ?? 0}
            onBlur={(event) => {
              const value = event.target.value;
              if (Number(value) !== valueOn) {
                updateITHTimer({
                  id: '7',
                  sector: String(sectorId),
                  start: String(value),
                  stop: String(valueOff),
                  serialNumber,
                });
              }
            }}
            type={'number'}
            min={0}
          />
        </FormItem>
      </div>
      <div className="min-w-[80px]">
        <FormItem label={labelOff}>
          <Input
            key={valueOff}
            placeholder={labelOff}
            defaultValue={valueOff ?? 0}
            onBlur={(event) => {
              const value = event.target.value;
              if (Number(value) !== valueOff) {
                updateITHTimer({
                  id: '7',
                  sector: String(sectorId),
                  start: String(valueOn),
                  stop: String(value),
                  serialNumber,
                });
              }
            }}
            type={'number'}
            min={0}
          />
        </FormItem>
      </div>
    </div>
  );
};
