import Select from 'design-system/select';

export const ITHConfigSelect = ({
  onChangeValue,
  label,
  value,
  options,
}: {
  onChangeValue: (value: string) => void;
  label: string;
  value: string;
  options: { name: string; value: string }[];
}) => (
  <div className="p-3 bg-neutral-500/20 rounded-xl w-full">
    <Select name={label} label={label} options={options} onChangeValue={onChangeValue} value={value} />
  </div>
);
