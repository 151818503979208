import { ITHConfigInput } from './ITHConfigInput';

function ITHConfigTypeSalidasCamasVentiladores({ sectorId }: { sectorId: number }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
      {[...(Array.from({ length: 4 }, (_, i) => i + 1) as number[])].map((timerId) => (
        <div key={sectorId} className="flex flex-col w-full gap-4 p-4 bg-neutral-900 rounded-md">
          <h3 className="text-lg font-semibold">Sector {timerId}</h3>
          <div className="w-full grid grid-cols-2 gap-2 sm:gap-6 md:gap-8">
            <ITHConfigInput label="Ventiladores" onBlur={() => {}} />
            <ITHConfigInput label="Cant de Vent" onBlur={() => {}} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ITHConfigTypeSalidasCamasVentiladores;
