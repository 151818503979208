import { Redirect, Route, Switch } from 'react-router-dom';
import Cmas from 'screens/cmas/Cmas';
import Configuracion from 'screens/config';
import CreadorDescargas from 'screens/creador-descargas';
import CreadorRecetas from 'screens/creador-recetas';
import Descargas from 'screens/descargas/Descargas';
import HistoricoIngredientes from 'screens/historico-ingredientes/HistoricoIngredientes';
import Home from 'screens/home';
import Ingredientes from 'screens/ingredientes';
import ITH from 'screens/ith';
import ITHHistorico from 'screens/ith-historico';
import Login from 'screens/login';
import Lotes from 'screens/lotes';
import LotesHistorico from 'screens/lotes-historico/LotesHistorico';
import MensajesControl from 'screens/mensajes-control';
import Mezclado from 'screens/mezclado/Mezclado';
import Pesadas from 'screens/pesadas/Pesadas';
import RegistroEventos from 'screens/registro-eventos/RegistroEventos';
import StockHistorico from 'screens/stock-historico/StockHistorico';
import Ubicacion from 'screens/ubicacion/Ubicacion';
import { PrivateRoute } from 'utils/PrivateRoute';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faEye,
  faEyeSlash,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import AppNavigation from 'components/app-navigation';
import { FullScreenLoader } from 'components/loader';
import { useAuth } from 'hooks/useAuth';
import { useCommonQueryParameters } from 'hooks/useCommonQueryParameters';
import { useRouting } from 'hooks/useRouting';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminDevices from 'screens/admin-devices/AdminDevices';
import AdminUsers from 'screens/admin-users/AdminUsers';
import Afimilk from 'screens/afimilk/Afimilk';
import CasHistorico from 'screens/cas-screens/cas-historico/CasHistorico';
import { CAS } from 'screens/cas-screens/cas/Cas';
import CmasHistorico from 'screens/cmas-historico/CmasHistorico';
import { GacAdminUsuario } from 'screens/gac-screens/gac-editar-usuario/GacAdminUsuario';
import { GacHistorico } from 'screens/gac-screens/gac-historico/GacHistorico';
import { GacRegistroEventos } from 'screens/gac-screens/gac-registro-eventos/GacRegistroEventos';
import { GacAdminVehiculos } from 'screens/gac-screens/gac-vehiculos/GacAdminVehiculos';
import { GAC } from 'screens/gac-screens/gac/GAC';
import ITHConfigHorarios from 'screens/ith-config-horarios';
import LecturaComederosRemota from 'screens/lectura-comederos-remota/LecturaComederosRemota';
import LecturaComederos from 'screens/lectura-comederos/LecturaComederos';
import ReporteCargas from 'screens/reporte-cargas/ReporteCargas';
import ReporteLotes from 'screens/reporte-lotes/ReporteLotes';
import ReporteMixeros from 'screens/reporte-mixeros/ReporteMixeros';
import ReportesSip from 'screens/reportes-sip/ReportesSip';
import { useGetDeviceLatestControlMessagesQuery, useGetDeviceTasksQuery } from 'store/devices/devicesApi';
import 'styles/global.scss';
import { ROUTE_PATH } from 'utils/constants/routes/types';
import Delpro from 'screens/delpro/Delpro';
import ConsumosPorLote from 'screens/consumos-por-lote/ConsumosPorLote';
import ITHConfigSectores from 'screens/ith-config-sectores';

library.add(faUser, faUserRegular, faArrowRight, faEye, faEyeSlash, faChevronLeft, faChevronRight);

function ProtectedHome() {
  const {
    loggedUser: { isLoading: isAuthLoading, isUserAdmin },
    is,
  } = useAuth();

  const { serialNumber } = useCommonQueryParameters();
  useGetDeviceLatestControlMessagesQuery({ serialNumber });
  useGetDeviceTasksQuery({ serialNumber });

  return (
    <div className="app-container">
      <AppNavigation />
      <div className="app-content">
        <ToastContainer theme="dark" position="bottom-right" />
        {isAuthLoading && <FullScreenLoader />}
        <Route exact path={ROUTE_PATH.HOME}>
          {isUserAdmin ? <AdminDevices /> : <Home />}
        </Route>
        <Route path={ROUTE_PATH.SIP_REPORTES}>
          <ReportesSip />
        </Route>
        <Route path={ROUTE_PATH.PESADAS}>
          <Pesadas />
        </Route>
        <Route path={ROUTE_PATH.REPORTE_CARGAS}>
          <ReporteCargas />
        </Route>
        <Route path={ROUTE_PATH.REPORTE_LOTES}>
          <ReporteLotes />
        </Route>
        <Route path={ROUTE_PATH.REPORTE_MIXEROS}>
          <ReporteMixeros />
        </Route>
        <Route path={ROUTE_PATH.CONSUMOS_POR_LOTE}>
          <ConsumosPorLote />
        </Route>
        <Route path={ROUTE_PATH.MEZCLADO}>
          <Mezclado />
        </Route>
        <Route path={ROUTE_PATH.DESCARGAS}>
          <Descargas />
        </Route>
        <Route path={ROUTE_PATH.CREADOR_DESCARGAS}>
          <CreadorDescargas />
        </Route>
        <Route path={ROUTE_PATH.INGREDIENTES}>
          <Ingredientes />
        </Route>
        <Route path={ROUTE_PATH.LOTES}>
          <Lotes />
        </Route>
        <Route path={ROUTE_PATH.HISTORICO_STOCK}>
          <StockHistorico />
        </Route>
        <Route path={ROUTE_PATH.HISTORICO_LOTES}>
          <LotesHistorico />
        </Route>
        <Route path={ROUTE_PATH.UBICACION}>
          <Ubicacion />
        </Route>
        <Route path={ROUTE_PATH.CREADOR_RECETAS}>
          <CreadorRecetas />
        </Route>
        <Route path={ROUTE_PATH.HISTORICO_INGREDIENTES}>
          <HistoricoIngredientes />
        </Route>
        <Route path={ROUTE_PATH.LECTURA_COMEDEROS}>
          <LecturaComederos />
        </Route>
        <Route path={ROUTE_PATH.LECTURA_COMEDEROS_REMOTA}>
          <LecturaComederosRemota />
        </Route>
        <Route path={ROUTE_PATH.AFIMILK}>
          <Afimilk />
        </Route>
        <Route path={ROUTE_PATH.DELPRO}>
          <Delpro />
        </Route>
        <Route path={ROUTE_PATH.REGISTRO_EVENTOS}>
          <RegistroEventos />
        </Route>
        <Route path={ROUTE_PATH.MENSAJES_CONTROL}>
          <MensajesControl />
        </Route>
        <Route path={ROUTE_PATH.ITH}>
          <ITH />
        </Route>
        <Route path={ROUTE_PATH.HISTORICO_ITH}>
          <ITHHistorico />
        </Route>
        {(is('DavidPennerAdmin') || is('HenrichHeinrichs')) && (
          <Route path={ROUTE_PATH.ITH_CONFIG_HORARIOS}>
            <ITHConfigHorarios />
          </Route>
        )}
        {(is('ITHCVPrueba')) && (
          <Route path={ROUTE_PATH.ITH_CONFIG_SECTORES}>
            <ITHConfigSectores />
          </Route>
        )}
        <Route path={ROUTE_PATH.GAC}>
          <GAC />
        </Route>
        <Route path={ROUTE_PATH.GAC_HISTORICO}>
          <GacHistorico />
        </Route>
        <Route path={ROUTE_PATH.GAC_REGISTRO_EVENTOS}>
          <GacRegistroEventos />
        </Route>
        <Route path={ROUTE_PATH.GAC_ADMIN_USUARIOS}>
          <GacAdminUsuario />
        </Route>
        <Route path={ROUTE_PATH.GAC_ADMIN_VEHICULOS}>
          <GacAdminVehiculos />
        </Route>
        <Route path={ROUTE_PATH.CAS}>
          <CAS />
        </Route>
        <Route path={ROUTE_PATH.CAS_HISTORICO}>
          <CasHistorico />
        </Route>
        <Route path={ROUTE_PATH.CMAS}>
          <Cmas />
        </Route>
        <Route path={ROUTE_PATH.HISTORICO_CMAS}>
          <CmasHistorico />
        </Route>
        <Route path={ROUTE_PATH.CONFIGURACION}>
          <Configuracion />
        </Route>
        <Route path={ROUTE_PATH.ADMIN_USUARIOS}>
          <AdminUsers />
        </Route>
      </div>
    </div>
  );
}

function App() {
  useRouting();
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute path="/*/:serialNumber">
        <ProtectedHome />
      </PrivateRoute>
      <PrivateRoute path={ROUTE_PATH.HOME}>
        <ProtectedHome />
      </PrivateRoute>
      <Redirect from="*" to={ROUTE_PATH.HOME} />
    </Switch>
  );
}

export default App;
