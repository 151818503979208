import { IconBarChart, IconCalendar, IconConfig, IconDevices, IconLocation, IconNodes } from 'design-system/icon';
import { ReactNode } from 'react';
import { DEVICE_TYPE_CAS, DEVICE_TYPE_CMAS, DEVICE_TYPE_GAC, DEVICE_TYPE_ITH, DEVICE_TYPE_ITH_CV, DEVICE_TYPE_SIP } from 'utils/device';
import { VERSION } from 'utils/helpers/validation';
import { ROUTE_PATH } from './types';

export const APP_NAME = 'Haasten';

export const ROUTE_GROUP_HOME = 'Principal';
export const ROUTE_GROUP_NAP = DEVICE_TYPE_SIP;
export const ROUTE_GROUP_CMAS = DEVICE_TYPE_CMAS;
export const ROUTE_GROUP_ITH = DEVICE_TYPE_ITH;
export const ROUTE_GROUP_GAC = DEVICE_TYPE_GAC;
export const ROUTE_GROUP_CAS = DEVICE_TYPE_CAS;
export const ROUTE_GROUP_CONFIGURACION = 'CONFIG';
export const EQUIPOS_NAP = [DEVICE_TYPE_SIP];
export const EQUIPOS_ITH = [DEVICE_TYPE_ITH];
export const EQUIPOS_CMAS = [DEVICE_TYPE_CMAS];
export const EQUIPOS_GAC = [DEVICE_TYPE_GAC];
export const EQUIPOS_CAS = [DEVICE_TYPE_CAS];

export const PURPOSE_TABLE = 'TABLE';
export const PURPOSE_CREATE = 'CREATE';
export const PURPOSE_HISTORY = 'HISTORY';

export type ROUTE = {
  subgroup: string;
  devices: string[];
  links: {
    label: string;
    link: string;
    purpose?: string;
    minVersion?: VERSION;
    maxVersion?: VERSION;
    Icon?: ReactNode;
    userRestricted?: string[];
  }[];
};

export const ROUTES: ROUTE[] = [
  {
    subgroup: ROUTE_GROUP_HOME,
    devices: [],
    links: [
      { label: 'Equipos', link: ROUTE_PATH.HOME, Icon: IconDevices },
      {
        label: 'Configuración',
        link: ROUTE_PATH.CONFIGURACION,
        Icon: IconConfig,
      },
    ],
  },
  {
    subgroup: ROUTE_GROUP_NAP,
    devices: EQUIPOS_NAP,
    links: [
      {
        label: 'Pesadas',
        link: ROUTE_PATH.PESADAS,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Mezclado',
        link: ROUTE_PATH.MEZCLADO,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Descargas',
        link: ROUTE_PATH.DESCARGAS,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Ubicación',
        link: ROUTE_PATH.UBICACION,
        purpose: PURPOSE_TABLE,
        Icon: IconLocation,
      },
      {
        label: 'Ingredientes',
        link: ROUTE_PATH.INGREDIENTES,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Lotes',
        link: ROUTE_PATH.LOTES,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Lectura de Comederos',
        link: ROUTE_PATH.LECTURA_COMEDEROS,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Lectura de Comederos Remota',
        link: ROUTE_PATH.LECTURA_COMEDEROS_REMOTA,
        purpose: PURPOSE_TABLE,
        Icon: IconNodes,
        minVersion: VERSION.FIVE,
      },
      {
        label: 'Reportes',
        link: ROUTE_PATH.SIP_REPORTES,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
        maxVersion: VERSION.SIX,
      },
      {
        label: 'Reporte de Cargas',
        link: ROUTE_PATH.REPORTE_CARGAS,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Reporte de Lotes',
        link: ROUTE_PATH.REPORTE_LOTES,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
        /*   minVersion: VERSION.FIVE, */
      },
      {
        label: 'Reporte Mixeros',
        link: ROUTE_PATH.REPORTE_MIXEROS,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Consumos por Lote',
        link: ROUTE_PATH.CONSUMOS_POR_LOTE,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
        minVersion: VERSION.SIX,
      },
      {
        label: 'AFIMILK',
        link: ROUTE_PATH.AFIMILK,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'DELPRO',
        link: ROUTE_PATH.DELPRO,
        purpose: PURPOSE_TABLE,
        Icon: IconBarChart,
      },
      {
        label: 'Creador de Descargas',
        link: ROUTE_PATH.CREADOR_DESCARGAS,
        purpose: PURPOSE_CREATE,
        Icon: IconNodes,
      },
      {
        label: 'Creador de Recetas',
        link: ROUTE_PATH.CREADOR_RECETAS,
        purpose: PURPOSE_CREATE,
        Icon: IconNodes,
      },
      {
        label: 'Histórico Stock',
        link: ROUTE_PATH.HISTORICO_STOCK,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
      {
        label: 'Histórico Lotes',
        link: ROUTE_PATH.HISTORICO_LOTES,
        purpose: PURPOSE_HISTORY,
        minVersion: VERSION.TWO,
        Icon: IconCalendar,
      },
      {
        label: 'Histórico Ingredientes',
        link: ROUTE_PATH.HISTORICO_INGREDIENTES,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
      {
        label: 'Registro de Eventos',
        link: ROUTE_PATH.REGISTRO_EVENTOS,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
      {
        label: 'Mensajes Control',
        link: ROUTE_PATH.MENSAJES_CONTROL,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
    ],
  },
  {
    subgroup: ROUTE_GROUP_ITH,
    devices: [DEVICE_TYPE_ITH, DEVICE_TYPE_ITH_CV],
    links: [
      { label: 'ITH', link: ROUTE_PATH.ITH, Icon: IconDevices },
      {
        label: 'ITH Histórico',
        link: ROUTE_PATH.HISTORICO_ITH,
        Icon: IconCalendar,
      },
      {
        label: 'Configuración ITH',
        link: ROUTE_PATH.ITH_CONFIG_HORARIOS,
        Icon: IconConfig,
        userRestricted: ['DavidPennerAdmin', 'HenrichHeinrichs'],
      },
      {
        label: 'Configuración ITH Sectores',
        link: ROUTE_PATH.ITH_CONFIG_SECTORES,
        Icon: IconConfig,
        userRestricted: ['ITHCVPrueba'],
      },
    ],
  },
  {
    subgroup: ROUTE_GROUP_CMAS,
    devices: [DEVICE_TYPE_CMAS],
    links: [
      { label: 'CMAS', link: ROUTE_PATH.CMAS, Icon: IconDevices },
      {
        label: 'CMAS Histórico',
        link: ROUTE_PATH.HISTORICO_CMAS,
        Icon: IconCalendar,
      },
    ],
  },
  {
    subgroup: ROUTE_GROUP_GAC,
    devices: [DEVICE_TYPE_GAC],
    links: [
      { label: 'GAC', link: ROUTE_PATH.GAC, Icon: IconDevices },
      {
        label: 'Histórico Cargas',
        link: ROUTE_PATH.GAC_HISTORICO,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
      {
        label: 'Registro de Eventos',
        link: ROUTE_PATH.GAC_REGISTRO_EVENTOS,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
      {
        label: 'Mensajes de Control',
        link: ROUTE_PATH.MENSAJES_CONTROL,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
      {
        label: 'Administrar Usuarios',
        link: ROUTE_PATH.GAC_ADMIN_USUARIOS,
        purpose: PURPOSE_TABLE,
        Icon: IconConfig,
      },
      {
        label: 'Administrar Vehículos',
        link: ROUTE_PATH.GAC_ADMIN_VEHICULOS,
        purpose: PURPOSE_TABLE,
        Icon: IconConfig,
      },
    ],
  },
  {
    subgroup: ROUTE_GROUP_CAS,
    devices: [DEVICE_TYPE_CAS],
    links: [
      { label: 'CAS', link: ROUTE_PATH.CAS, Icon: IconDevices },
      {
        label: 'CAS Histórico',
        link: ROUTE_PATH.CAS_HISTORICO,
        purpose: PURPOSE_HISTORY,
        Icon: IconCalendar,
      },
    ],
  },
];
