import FormCard, { FormCardIcon } from 'design-system/form-card';
import { IconConfig } from 'design-system/icon';
import Select from 'design-system/select';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDeviceSelected } from 'store/devices/devicesSlice';
import ITHConfigTypeCamasAsp from './ITHConfigTypeCamasAsp';
import ITHConfigTypeCamasVent from './ITHConfigTypeCamasVent';
import ITHConfigTypeModoITH from './ITHConfigTypeModoITH';
import ITHConfigTypeSalidasCamasAspersores from './ITHConfigTypeSalidasCamasAspersores';
import ITHConfigTypeSalidasCamasVentiladores from './ITHConfigTypeSalidasCamasVentiladores';
import ITHConfigTypeTunel from './ITHConfigTypeSalidasTunel';
import ITHConfigTypeSector from './ITHConfigTypeSector';

const minPossibleTime = new Date();
const maxPossibleTime = new Date();
minPossibleTime.setHours(0, 0, 0, 0);
maxPossibleTime.setHours(23, 59, 59, 999);

const ITH_SECTORES_OPTIONS = [
  { value: 'Sector 1', name: 'Sector 1', number: 1, type: 'Sector' },
  { value: 'Sector 2', name: 'Sector 2', number: 2, type: 'Sector' },
  { value: 'Sector 3', name: 'Sector 3', number: 3, type: 'Sector' },
  { value: 'Sector 4', name: 'Sector 4', number: 4, type: 'Sector' },
  { value: 'Cama Vent 1', name: 'Cama Vent 1', number: 1, type: 'CamaVent' },
  { value: 'Cama Vent 2', name: 'Cama Vent 2', number: 2, type: 'CamaVent' },
  { value: 'Cama Vent 3', name: 'Cama Vent 3', number: 3, type: 'CamaVent' },
  { value: 'Cama Vent 4', name: 'Cama Vent 4', number: 4, type: 'CamaVent' },
  { value: 'Cama Asp 1', name: 'Cama Asp 1', number: 1, type: 'CamaAsp' },
  { value: 'Cama Asp 2', name: 'Cama Asp 2', number: 2, type: 'CamaAsp' },
  { value: 'Cama Asp 3', name: 'Cama Asp 3', number: 3, type: 'CamaAsp' },
  { value: 'Cama Asp 4', name: 'Cama Asp 4', number: 4, type: 'CamaAsp' },
  { value: 'Salidas Túnel', name: 'Salidas Túnel', type: 'SalidasTunel' },
  { value: 'Salidas Camas Ventiladores', name: 'Salidas Camas Ventiladores', type: 'SalidasCamasVentiladores' },
  { value: 'Salidas Camas Aspersores', name: 'Salidas Camas Aspersores', type: 'SalidasCamasAspersores' },
  { value: 'Modos Ciclado', name: 'Modos Ciclado', type: 'ModosCiclado' },
  { value: 'Modos ITH', name: 'Modos ITH', type: 'ModosITH' },
];

function ITHConfigSectores() {
  const deviceSelected = useSelector(selectDeviceSelected);
  const { serialNumber = '' } = deviceSelected || {};

  const [sector, setSector] = useState<string>(ITH_SECTORES_OPTIONS[0].value);
  const sectorObj = ITH_SECTORES_OPTIONS.find((option) => option.value === sector);
  const sectorName = sectorObj?.name;
  const sectorNumber = sectorObj?.number || 0;
  const sectorType = sectorObj?.type;

  return (
    <div className="flex flex-col">
      <div className="content-view px-8 py-8 flex flex-col gap-3">
        <FormCard
          title={`Configuración ITH - ${sectorName}`}
          subtitle={`${serialNumber}`}
          icon={
            <FormCardIcon className="px-2 py-2">
              <IconConfig />
            </FormCardIcon>
          }
          items={[
            {
              value: (
                <div className="w-full sm:w-72">
                  <Select
                    label="Elegir Sector"
                    options={ITH_SECTORES_OPTIONS || []}
                    onChangeValue={(value) => {
                      setSector(value);
                    }}
                    value={sector}
                  />
                </div>
              ),
            },
            ...(sectorType === 'Sector' ? [{ value: <ITHConfigTypeSector sectorId={sectorNumber} /> }] : []),
            ...(sectorType === 'CamaVent' ? [{ value: <ITHConfigTypeCamasVent sectorId={sectorNumber} /> }] : []),
            ...(sectorType === 'CamaAsp' ? [{ value: <ITHConfigTypeCamasAsp sectorId={sectorNumber} /> }] : []),
            ...(sectorType === 'SalidasTunel' ? [{ value: <ITHConfigTypeTunel sectorId={sectorNumber} /> }] : []),
            ...(sectorType === 'SalidasCamasAspersores'
              ? [{ value: <ITHConfigTypeSalidasCamasAspersores sectorId={sectorNumber} /> }]
              : []),
            ...(sectorType === 'SalidasCamasVentiladores'
              ? [{ value: <ITHConfigTypeSalidasCamasVentiladores sectorId={sectorNumber} /> }]
              : []),
            ...(sectorType === 'ModosITH' ? [{ value: <ITHConfigTypeModoITH sectorId={sectorNumber} /> }] : []),
            ...(sectorType === 'ModosCiclado' ? [{ value: <ITHConfigTypeModoITH sectorId={sectorNumber} /> }] : []),
          ]}
        />
      </div>
    </div>
  );
}

export default ITHConfigSectores;
