import { FormItem, Input } from 'design-system/input';

export const ITHConfigInput = ({
  onBlur,
  label,
}: {
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  label: string;
}) => (
  <div className="p-3 bg-neutral-500/20 rounded-xl w-full">
    <FormItem label={label}>
      <Input placeholder={label} defaultValue={0} onBlur={onBlur} type={'number'} min={0} />
    </FormItem>
  </div>
);
