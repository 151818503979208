import { ITHConfigClock } from './ITHConfigClock';
import { ITHConfigClockRango } from './ITHConfigClockRango';

function ITHConfigTypeCamasVent({ sectorId }: { sectorId: number }) {
  return (
    <div className="flex flex-col gap-12">
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-6 md:gap-8">
        {[1, 2, 3, 4, 5, 6].map((timerId) => (
          <ITHConfigClock timerId={timerId} sectorId={sectorId} />
        ))}
      </div>

      <div className="px-4 py-5 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:px-6 items-center">
        <dt className="text-sm font-medium ">Rango de trabajo ITH</dt>
        <dd className="mt-1 text-sm tablet:col-span-2 tablet:mt-0">
          <ITHConfigClockRango sectorId={sectorId} labelOn="ITH ON" labelOff="ITH OFF" />
        </dd>
      </div>
    </div>
  );
}

export default ITHConfigTypeCamasVent;
