import { ITHConfigClock } from './ITHConfigClock';
import { ITHConfigInput } from './ITHConfigInput';

function ITHConfigTypeSector({ sectorId }: { sectorId: number }) {
  return (
    <div className="flex flex-col gap-12">
      {[...(Array.from({ length: 24 }, (_, i) => i + 1) as number[])].map((timerId) => (
        <div key={sectorId} className="flex items-center flex-wrap lg:flex-nowrap gap-2 sm:gap-6 md:gap-8">
          <div className="w-full sm:w-auto">
            <ITHConfigClock timerId={timerId} sectorId={sectorId} />
          </div>
          <div key={sectorId} className="grid grid-cols-4 gap-2 sm:gap-6 md:gap-8">
            <ITHConfigInput label="Ciclo" onBlur={() => {}} />
            <ITHConfigInput label="ITH" onBlur={() => {}} />
            <ITHConfigInput label="Modo EV" onBlur={() => {}} />
            <ITHConfigInput label="Conjunto" onBlur={() => {}} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ITHConfigTypeSector;
